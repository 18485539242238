import { render, staticRenderFns } from "./Upload.vue?vue&type=template&id=0bc99548&scoped=true&"
import script from "./Upload.vue?vue&type=script&lang=js&"
export * from "./Upload.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bc99548",
  null
  
)

export default component.exports