<template>
    <div :id="id" v-loading="loading">
        <slot></slot>
    </div>
</template>

<script>
    import plupload from 'plupload'

    export default {
        name: 'Upload',
        props: {
            id: String,
            src: String,
            oss: Object
        },
        data() {
            return {
                loading: false,
                config: null,
            }
        },
        created() {
            this.config = this.oss
        },
        mounted() {
            if (!this.config)
                this.getConfig()
            else {
                this.initUpload()
            }
        },
        methods: {
            getConfig() {
                const url = 'common/oss/token?dir=' + this.src
                this.$https.get(url).then(data => {
                    if (data) {
                        if (data.host.indexOf('ddresource' === -1)) data.host = 'ddresource.' + data.host
                        if (data.host.indexOf('http' === -1)) data.host = 'https://' + data.host
                        this.config = data
                        this.initUpload()
                    }
                })
            },
            async initUpload() {
                const {host, dir, policy, accessid, signature} = this.config
                let fileTimestamp = ''
                const that = this
                const pluploader = new plupload.Uploader({
                    runtimes: 'html5, flash, silverlight, html4',
                    browse_button: this.id,
                    multi_selection: false,
                    flash_swf_url: '/plupload/js/Moxie.swf',
                    silverlight_xap_url: '/plupload/js/Moxie.xap',
                    url: host,
                    init: {
                        FilesAdded: (up, files) => {
                            this.loading = true
                            const suffix = files[0].name.split('.').pop()
                            fileTimestamp = this.setTimestamp()
                            const newFilePath = `${fileTimestamp}.${suffix}`
                            pluploader.setOption({
                                multipart_params: {
                                    key: dir + '/' + newFilePath,
                                    policy: policy,
                                    OSSAccessKeyId: accessid,
                                    success_action_status: '200',
                                    signature: signature
                                }
                            })
                            pluploader.start()
                        },
                        FileUploaded: (up, file, info) => {
                            this.loading = false
                            const suffix = file.name.split('.').pop()
                            const newFilePath = `${fileTimestamp}.${suffix}`
                            if (info.status === 200) {
                                const imgurl = `${host}/${dir}/${newFilePath}`
                                that.$emit('success', imgurl)
                            }
                        }
                    }
                })
                pluploader.init()
            },
            setTimestamp() {
                return this.$dayjs().valueOf()
            }
        }
    }
</script>

<style scoped lang="scss">
</style>
